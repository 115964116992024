import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from "@mui/material";
import { GridCloseIcon } from "@mui/x-data-grid";
import ReactJson from "react-json-view";

const Preferences = ({ preferencesOpen, preferenceData, handlePreferencesClose }) => {

    return (
        <Dialog fullWidth={true} open={preferencesOpen} onClose={handlePreferencesClose}>
            <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
                Profile Preferences
                <IconButton sx={{ ml: 'auto' }} onClick={handlePreferencesClose}>
                    <GridCloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    When reading Filter settings, the higher the number the more "offensive" content the user will see
                    (i.e. a 0 means they don't want to see any offensive content, so all filters will be applied)
                    <br /><br />
                    {(preferenceData || {}).sameAsDefault && <><b>*** Same as Profile Default ***</b><br /><br /></>}
                    {(preferenceData || {}).sameAsDefault === false && <><b>*** Custom Show Preferences ***</b><br /><br /></>}
                </DialogContentText>
                <ReactJson src={preferenceData} collapsed={2} displayObjectSize={false} displayDataTypes={false} />
            </DialogContent>
            <DialogActions>
                <Button onClick={handlePreferencesClose}>OK</Button>
            </DialogActions>
        </Dialog>
    );
};
export default Preferences;

