import React, { useEffect, useState } from 'react';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import WarningIcon from '@mui/icons-material/Warning';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import "./iaptools.scss";
import { Link } from 'react-router-dom';
import { styled } from '@mui/material';

const { REACT_APP_TOKEN_NAME, REACT_APP_REFRESH_TOKEN_NAME, REACT_APP_TSDB_SOCKET } = process.env;

const Braintree = () => {
    const checkboxes = [
        { label: 'All', status: '', count: () => results.length },
        { label: 'Not Found', status: 'notFound', defaultChecked: true, count: () => statusCounts['notFound'] || 0 },
        { label: 'Multiple Found', status: 'multipleFound', defaultChecked: true, count: () => statusCounts['multipleFound'] || 0 },
        { label: 'No IAP Plan', status: 'noIapPlan', defaultChecked: true, count: () => statusCounts['noIapPlan'] || 0 },
        { label: 'Customer ID Mismatch', status: 'customerIdMismatch', defaultChecked: true, count: () => statusCounts['customerIdMismatch'] || 0 },
        { label: 'Plan Mismatch', status: 'planMismatch', defaultChecked: true, count: () => statusCounts['planMismatch'] || 0 },
        { label: 'No Active Plan', status: 'noActivePlan', defaultChecked: true, count: () => statusCounts['noActivePlan'] || 0 },
        { label: 'Email Mismatch', status: 'emailMismatch', defaultChecked: true, count: () => statusCounts['emailMismatch'] || 0 },
        { label: 'Switched to Native IAP', status: 'customerSwitchedIAP', count: () => statusCounts['customerSwitchedIAP'] || 0 },
        { label: 'Incomplete Signup', status: 'signupIncomplete', count: () => statusCounts['signupIncomplete'] || 0 },
        { label: 'OK', status: 'ok', count: () => statusCounts['ok'] || 0 },
    ];

    const initialStatuses = checkboxes.filter(checkbox => checkbox.defaultChecked).map(checkbox => checkbox.status);
    const [ws, setWs] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [results, setResults] = useState([]); // State for storing reconciliation results
    const [checkedStatuses, setCheckedStatuses] = useState(initialStatuses);
    const [statusCounts, setStatusCounts] = useState({});
    const [isProcessing, setIsProcessing] = useState(false);
    const [lastResultsDate, setLastResultsDate] = useState('');
    const [progressPercent, setProgressPercent] = useState(0);

    useEffect(() => {
        const storedData = localStorage.getItem('btReconciliationResults');
        if (storedData) {
            const { date, results } = JSON.parse(storedData);
            setResults(results);
            setLastResultsDate(date);
        }
    }, []); // This effect runs only once when the component mounts

    useEffect(() => {
        const counts = countStatuses(results); // Assuming countStatuses is the function that calculates counts
        setStatusCounts(counts);
    }, [results]); // This effect runs whenever `results` changes

    useEffect(() => {
        // Cleanup function to run when the component unmounts or before a new WebSocket is initialized
        return () => {
            if (ws) {
                ws.close();
            }
        };
    }, [ws]);

    const handleCheckboxChange = (status) => {
        setCheckedStatuses(prev => {
            if (prev.includes(status)) {
                return prev.filter(s => s !== status); // Uncheck: Remove the status from the array
            } else {
                return [...prev, status]; // Check: Add the status to the array
            }
        });
    };
    // Function to filter results based on the current filter
    const filteredResults = checkedStatuses.length === 0 || checkedStatuses.includes('')
        ? results
        : results.filter(item => checkedStatuses.includes(item.status));

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsProcessing(false);
        setResults([]); // Clear the results when a new file is selected
        setProgressPercent(0);
    };

    const countStatuses = (results) => {
        return results.reduce((acc, { status }) => {
            acc[status] = acc[status] ? acc[status] + 1 : 1;
            return acc;
        }, {});
    };

    const storeResults = (results) => {
        const dataToStore = {
            date: new Date().toISOString(), // Get the current date and time in ISO format
            results,
        };
        localStorage.setItem('btReconciliationResults', JSON.stringify(dataToStore));
    };

    const handleFileUpload = async () => {
        if (selectedFile) {
            setIsProcessing(true);
            setProgressPercent(0);
            const reader = new FileReader();

            reader.onload = function (event) {
                const csvData = event.target.result; // The CSV file content as text
                // Send the CSV data to the server through WebSocket
                // Clean up previous WebSocket connection if it's closed or closing
                if (ws && (ws.readyState === WebSocket.CLOSED || ws.readyState === WebSocket.CLOSING)) {
                    ws.close();
                    setWs(null);
                }
                // Proceed to create a new WebSocket connection if it's not already open or connecting
                if (!ws || (ws.readyState !== WebSocket.OPEN && ws.readyState !== WebSocket.CONNECTING)) {
                    const newWs = new WebSocket(`${REACT_APP_TSDB_SOCKET}/admin/ws`);
                    setWs(newWs);

                    newWs.onmessage = (event) => {
                        try {
                            const { status, action, data } = JSON.parse(event.data);
                            if (status === 'error') {
                                console.error('Error:', JSON.stringify(data), 'Action:', action);
                                newWs.close();
                            } else if (status === 'success') {
                                console.log('Success:', data);
                                if (action === 'login') {
                                    newWs.send(JSON.stringify({ action: 'reconcileBraintreeCustomers', data: { csvData } }));
                                    setIsProcessing(true);
                                    // console.log('CSV file uploaded successfully');
                                    // newWs.send(JSON.stringify({ action: 'ping', data: {} }));
                                } else if (action === 'reconcileBraintreeCustomers') {
                                    console.log('CSV file uploaded successfully. Server is reconciling the data. Please wait...');
                                } else if (action === 'progress') {
                                    console.log('Progress:', data);
                                    setProgressPercent(data.percent);
                                    if (data.results) setResults(data.results);
                                    // const progressElement = document.getElementById('processingProgress');
                                    // progressElement.value = data.percent;
                                } else if (action === 'complete') {
                                    console.log('Reconciliation results:', data);
                                    setResults(data.results);
                                    storeResults(data.results);
                                    newWs.close();
                                }
                            } else if (status === 'canceled') {
                                console.log('Action canceled:', action);
                                setIsProcessing(true);
                                newWs.close();
                            }
                        } catch (error) {
                            console.error('Error:', error);
                            newWs.close();
                        }
                    }

                    newWs.onopen = () => {
                        const token = localStorage.getItem(REACT_APP_TOKEN_NAME);
                        const refreshToken = localStorage.getItem(REACT_APP_REFRESH_TOKEN_NAME);
                        newWs.send(JSON.stringify({ action: 'login', data: { [REACT_APP_TOKEN_NAME]: token, [REACT_APP_REFRESH_TOKEN_NAME]: refreshToken } }));
                    };

                    newWs.onclose = newWs.onerror = () => {
                        setIsProcessing(false);
                        setWs(null); // Reset the WebSocket state to allow reinitialization
                    };
                }
            };

            reader.onerror = function (event) {
                console.error("File could not be read! Code " + event.target.error.code);
                setIsProcessing(false);
            };

            reader.readAsText(selectedFile); // Read the file's content as text
        }
    };

    const handleCancel = () => {
        if (ws && ws.readyState === WebSocket.OPEN) {
            ws.send(JSON.stringify({ action: 'reconcileBraintreeCustomers', abort: true, data: {} }));
        }
    };

    const NoMaxWidthTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))({
        [`& .${tooltipClasses.tooltip}`]: {
            maxWidth: 'none',
        },
    });
    return (
        <div className="iaptools">
            <div className="iaptoolsContainer">
            <div className="top" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img src="https://www.braintreepayments.com/images/braintree-logo-black.png" alt="Logo" style={{ height: '50px', marginRight: '10px' }} />
                </div>
                <div className="top">
                    <h1 className="title">Reconcile Braintree Customers to Enjoy Users</h1>
                </div>
                <div className="bottom">
                    <p className="desc">
                        Go to "Subscriptions", clicl "Search", then "Download Subscriptions" from Braintree<br />Then upload the CSV here.
                    </p>
                    <p className="desc">Impact Notice: Running this report is basically harmless (no db updates)</p>
                    <input type="file" accept=".csv" disabled={isProcessing} onChange={handleFileChange} />
                    <button
                        style={{ backgroundColor: isProcessing ? 'red' : '' }}
                        onClick={isProcessing ? handleCancel : handleFileUpload}
                        disabled={!selectedFile}
                    >
                        {isProcessing ? 'Cancel' : 'Upload & Process'}
                    </button>
                    <div className="progressContainer">
                        <progress id="processingProgress" max="100" value={progressPercent}></progress>
                        <p className="progressText desc">{progressPercent}% Complete</p>
                    </div>
                    <div className="lastResults">
                        {lastResultsDate && (
                            <p className="lastProcessedDate">
                                Last processed: {new Date(lastResultsDate).toLocaleString()}
                                &nbsp;<button className="linkButton" onClick={() => {
                                    localStorage.removeItem('btReconciliationResults')
                                    setResults([]);
                                    setLastResultsDate('');
                                }}>Clear</button>
                            </p>
                        )}
                    </div>
                </div>
                <div className="contentContainer">
                    <div className="checkboxContainer">
                        <div className="filters">
                            <div className="checkboxWrapper">
                                {checkboxes.map((checkbox, index) => (
                                    <label key={index}>
                                        <input
                                            type="checkbox"
                                            onChange={() => handleCheckboxChange(checkbox.status)}
                                            checked={checkedStatuses.includes(checkbox.status)}
                                        />
                                        {checkbox.label} ({checkbox.count()})
                                    </label>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="resultsContainer">
                        <table>
                            <thead>
                                <tr>
                                    <th>Status</th>
                                    <th>Email</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredResults.map((item, index) => (
                                    <tr key={index}>
                                        <td>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                {item.message && (
                                                    <NoMaxWidthTooltip
                                                        title={
                                                            <div style={{ userSelect: 'text', fontSize: '1rem', textAlign: 'center' }}>
                                                                {item.message}<br /><br />
                                                                <p style={{ fontSize: '12px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                    <ContentCopyIcon
                                                                        style={{ cursor: 'pointer', marginLeft: '10px' }}
                                                                        onClick={() => navigator.clipboard.writeText(item.email)}
                                                                    />Email
                                                                    <ContentCopyIcon
                                                                        style={{ cursor: 'pointer', marginLeft: '10px' }}
                                                                        onClick={() => navigator.clipboard.writeText(item.customerId)}
                                                                    />Customer ID
                                                                </p>
                                                            </div>
                                                        }
                                                        interactive
                                                    >
                                                        <WarningIcon className="warning-icon" />
                                                    </NoMaxWidthTooltip>
                                                )}
                                                {item.status}
                                            </div>
                                        </td>
                                        <td>
                                            {!!item.legacyUserId && <Link to={`/users/${item.legacyUserId}`} target="_blank" rel="noreferrer">
                                                {item.email}
                                            </Link>}
                                            {!item.legacyUserId && item.email}
                                            {<span style={{ marginLeft: '10px' }}>
                                                <Link to={`https://www.braintreegateway.com/merchants/bn2nxfvnncf87rbn/customers/${item.customerId}`} target="_blank" rel="noreferrer">
                                                    bt
                                                </Link>
                                            </span>}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Braintree;
