import React, { useContext } from 'react';
import "./sidebar.scss";
import Dashboardicon from "@mui/icons-material/Dashboard";
import PersonOutlineicon from "@mui/icons-material/PersonOutline";
import StoreIcon from "@mui/icons-material/Store";
import InsertCharticon from "@mui/icons-material/InsertChart";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import SettingsApplicationsicon from "@mui/icons-material/SettingsApplications";
import ExitToAppicon from "@mui/icons-material/ExitToApp";
import NotificationsNoneicon from "@mui/icons-material/NotificationsNone";
import SettingsSystemDaydreamOutlinedicon from "@mui/icons-material/SettingsSystemDaydreamOutlined";
import PsychologyOutlinedicon from "@mui/icons-material/PsychologyOutlined";
import AccountCircleOutlinedicon from "@mui/icons-material/AccountCircleOutlined";
import { DarkModeContext } from '../../context/darkModeContext';
import { ApiContext } from "../../context/apiContext"
import { Link, useNavigate } from "react-router-dom";

const Sidebar = () => {
    const { dispatch } = useContext(DarkModeContext);
    const { logout } = useContext(ApiContext);
    const navigate = useNavigate();
    return (
        <div className='sidebar'>
            <div className="top">
                <Link to="/" style={{ textDecoration: "none" }}>
                    <span className='logo'>enjoy admin</span>
                </Link>
            </div>
            <hr />
            <div className="center">
                <ul>
                    <p className="title">MAIN</p>
                    <Link to="/" style={{ textDecoration: "none" }}>
                        <li>
                            <Dashboardicon className="icon" />
                            <span>Dashboard</span>
                        </li>
                    </Link>
                    <p className="title">LISTS</p>
                    <Link to="/users" style={{ textDecoration: "none" }}>
                        <li>
                            <PersonOutlineicon className="icon" />
                            <span>Users</span>
                        </li>
                    </Link>
                    <Link to="/providers" style={{ textDecoration: "none" }}>
                        <li>
                            <StoreIcon className="icon" />
                            <span>Providers</span>
                        </li>
                    </Link>
                    {/* <li>
                        <CreditCardicon className="icon" />
                        <span>Orders</span>
                    </li>
                    <li>
                        <LocalShippingicon className="icon" />
                        <span>Delivery</span>
                    </li> */}
                    <p className="title">IAP TOOLS</p>
                    <Link to="/iaptools/amazon" style={{ textDecoration: "none" }}>
                        <li>
                            <MonetizationOnOutlinedIcon className="icon" />
                            <span>Amazon</span>
                        </li>
                    </Link>
                    <Link to="/iaptools/apple" style={{ textDecoration: "none" }}>
                        <li>
                            <MonetizationOnOutlinedIcon className="icon" />
                            <span>Apple</span>
                        </li>
                    </Link>
                    <Link to="/iaptools/braintree" style={{ textDecoration: "none" }}>
                        <li>
                            <MonetizationOnOutlinedIcon className="icon" />
                            <span>Braintree</span>
                        </li>
                    </Link>
                    {/* <Link to="/iaptools/google" style={{ textDecoration: "none" }}>
                        <li>
                            <MonetizationOnOutlinedIcon className="icon" />
                            <span>Google</span>
                        </li>
                    </Link> */}
                    <Link to="/iaptools/roku" style={{ textDecoration: "none" }}>
                        <li>
                            <MonetizationOnOutlinedIcon className="icon" />
                            <span>Roku</span>
                        </li>
                    </Link>
                    <p className="title">USEFUL</p>
                    <li>
                        <InsertCharticon className="icon" />
                        <span>Stats</span>
                    </li>
                    <li>
                        <NotificationsNoneicon className="icon" />
                        <span>Notifications</span>
                    </li>
                    <p className="title">SERVICE</p>
                    <li>
                        <SettingsSystemDaydreamOutlinedicon className="icon" />
                        <span>System Health</span>
                    </li>
                    <li>
                        <PsychologyOutlinedicon className="icon" />
                        <span>Logs</span>
                    </li>
                    <li>
                        <SettingsApplicationsicon className="icon" />
                        <span>Settings</span>
                    </li>
                    <p className="title">USER</p>
                    <li>
                        <AccountCircleOutlinedicon className="icon" />
                        <span>Profile</span>
                    </li>
                    <li>
                        <ExitToAppicon className="icon" />
                        <span onClick={() => { logout(); navigate("/login"); }}>Logout</span>
                    </li>
                </ul>
            </div>
            <div className="bottom">
                <div
                    className="colorOption"
                    onClick={() => dispatch({ type: "LIGHT" })}
                ></div>
                <div
                    className="colorOption"
                    onClick={() => dispatch({ type: "DARK" })}
                ></div>
            </div>
        </div>
    )
}

export default Sidebar;