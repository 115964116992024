import { Outlet } from "react-router-dom/dist";
import Navbar from "./navbar/Navbar";
import Sidebar from "./sidebar/Sidebar";
import "./mainlayout.scss";

export const MainLayout = () => (
    <div className="main">
        <Sidebar />
        <div className='mainContainer'>
            <Navbar />
            <Outlet />
        </div>
    </div>
);
