import React, { useContext, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { Grid, Paper, Avatar, TextField, Button } from '@material-ui/core'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import gql from 'graphql-tag';
import "./login.scss";
import { ApiContext } from "../../context/apiContext"
import { AppStateContext } from "../../context/appStateContext"


const loginMutation = gql`
    mutation($email: String!, $password: String!, $rememberMe: Boolean!) {
        login(email: $email, password: $password, rememberMe: $rememberMe) {
            ok
            userId
            email
            token
            refreshToken
            selector
            validator
            activeProfileId
            Profiles {
                id
                name
                avatarUrl
                pin
                securedByPIN
                Permissions {
                    name
                    accessLevel
                }
            }
            errors {
                path
                message
            }
        }
    }
`;

const setActiveProfile = gql`
    mutation ($userId: Int!, $profileId: Int!) {
        ActiveProfile: setActiveProfile (userId: $userId, profileId: $profileId) {
            ok
            errors {
                path
                message
            }
            # user { id }
            token
            refreshToken
        }
    }
`;

const { REACT_APP_TOKEN_NAME, REACT_APP_REFRESH_TOKEN_NAME } = process.env;

const Login = () => {
    const [username, setUserName] = useState();
    const [password, setPassword] = useState();
    const [errorMessage, setErrorMessage] = useState();
    const { authClient, logout } = useContext(ApiContext);
    const { setAppState } = useContext(AppStateContext);
    const navigate = useNavigate();

    const paperStyle = { padding: 20, width: 280, margin: "20px auto" }
    const avatarStyle = { backgroundColor: '#1bbd7e' }
    const btnstyle = { margin: '8px 0' }

    const login = async () => {
        const response = await authClient.request(loginMutation, { email: username, password, rememberMe: false });
        const { ok, networkError, errors, userId, Profiles } = response.login || {};
        try {
            if (ok) {
                const { login: user } = response;
                let { token, refreshToken } = user;
                // select first profile that has dashboard permission
                const profile = Profiles.find((entry) => entry.Permissions.find((perm) => perm.name === 'DASHBOARD'));
                if (!profile) throw new Error('dashboard access denied', Profiles);
                // set the active profile
                const res = await authClient.request(setActiveProfile, { userId, profileId: profile.id });
                const { ok: ok2, errors: errors2 } = res.ActiveProfile || {};
                if (!ok2) throw new Error('Couldn\'t set active profile', ((errors2 || [])[0] || '').message);
                ({ token, refreshToken } = res.ActiveProfile);
                localStorage.setItem(REACT_APP_TOKEN_NAME, token);
                localStorage.setItem(REACT_APP_REFRESH_TOKEN_NAME, refreshToken);
                user.activeProfileId = profile.id;
                user.activeProfile = profile;
                setAppState({ adminUser: user });
                navigate("/");
            } else {
                if (networkError) logout(); // clear out potentially bad tokens
                const errorMessage = networkError || ((errors || [])[0] || '').message;
                throw Error(errorMessage);
            }
        } catch (error) {
            setErrorMessage(error.message);
            setTimeout(setErrorMessage, 3000);

        }
        // accept all username/password combinations
        return Promise.resolve();
    };


    return (
        <Grid>
            <Paper elevation={10} style={paperStyle}>
                <Grid align='center'>
                    <Avatar style={avatarStyle}><LockOutlinedIcon /></Avatar>
                    <h2>Sign In</h2>
                </Grid>
                <TextField label='Username' onChange={e => setUserName(e.target.value)} placeholder='Enter username' fullWidth required />
                <TextField label='Password' onChange={e => setPassword(e.target.value)} placeholder='Enter password' type='password' fullWidth required />
                <br /><br />
                <Button type='submit' color='primary' onClick={login} variant="contained" style={btnstyle} fullWidth>Sign in</Button>
                <Grid align='center'>
                    <p className='errorMessage'>{errorMessage}</p>
                </Grid>
            </Paper>

        </Grid>
    )
}

export default Login