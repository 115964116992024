import "./datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useCallback, useContext, useEffect, useState } from "react";
import { ApiContext } from "../../context/apiContext"
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useMediaQuery } from "@mui/material";
import { createShortenedDateTime } from "../../utils/utils";
import { getProfilePreferenceQuery, getWatchLogsViewQuery } from "../../queries";
import Preferences from "../dialogs/Preferences";

const userColumns = [
  {
    field: "id",
    headerName: "ID",
    width: 220,
    filterable: false,
    // renderCell: (params) => {
    //   return <a href={`./watchlog/${params.row.id}`} target="_blank" rel="noreferrer" className="viewButton">{params.row.id}</a>;
    // },
  },
  {
    field: "Show.title",
    headerName: "Show",
    width: 230,
    valueGetter: ({ row }) => {
      const { Show, SeriesGroup, seasonNumber, episodeNumber } = row;
      if (SeriesGroup) {
        return `${SeriesGroup.title}: S${seasonNumber}E${episodeNumber}`;
      }
      return Show.title;
    },
  },
  {
    field: "Site",
    headerName: "Site",
    width: 230,
    valueGetter: ({ row }) => {
      const { siteName } = row.exosite || {};
      return siteName;
    }
  },
  {
    field: "PcntWatched",
    headerName: "% Watched",
    width: 230,
    filterable: false,
    valueGetter: ({ row }) => {
      const { longestVideoTime, videoDuration } = row;
      return videoDuration > 0 ? `${Math.round(longestVideoTime / videoDuration * 100)}%` : "UNK";
    }
  },
  {
    field: "updatedAt",
    headerName: "Updated",
    width: 230,
    filterable: false,
    valueGetter: ({ row }) => {
      const updatedAt = createShortenedDateTime(row.updatedAt);
      return updatedAt;
    },
  },
  {
    field: "createdAt",
    headerName: "Created",
    width: 230,
    filterable: false,
    hide: true,
    valueGetter: ({ row }) => {
      const createdAt = createShortenedDateTime(row.createdAt);
      return createdAt;
    },
  },
  //   field: "subscriptionLevelCode",
  //   headerName: "Plan",
  //   width: 120,
  //   sortable: false,
  //   renderCell: (params) => {
  //     let code = params.row.activeSubscriptionLevelCode;
  //     let toCode = params.row.subscriptionLevelCode;
  //     return (
  //       <div className={`cellWithStatus ${code}`}>
  //         {code}
  //         {(
  //           code !== 'FREE' && toCode === 'FREE' &&
  //           <KeyboardArrowDownIcon fontSize="small" style={{ color: 'red', verticalAlign: 'middle' }} />
  //         )}
  //       </div>
  //     );
  //   },
  // }, {
  //   field: "paymentMethod",
  //   headerName: "Pay Method",
  //   width: 120,
  //   sortable: false,
  //   filterable: false,
  //   renderCell: (params) => {
  //     let paymentMethod = params.row.paymentMethod
  //     if (paymentMethod === 'IAP') paymentMethod = params.row.iapPlatform || paymentMethod;
  //     return paymentMethod;
  //   },
  // }, {
  //   field: "createdAt",
  //   headerName: "Created",
  //   width: 180,
  //   filterable: false,
  //   renderCell: (params) => {
  //     const createdAt = createShortenedDateTime(params.row.createdAt);
  //     return createdAt;
  //   },
  // }
];


const WatchLogTable = ({ profile }) => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [rowCount, setRowCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [preferencesOpen, setPreferencesOpen] = useState(false);
  const [preferenceData, setPreferenceData] = useState({});
  const [queryOptions, setQueryOptions] = useState({});
  const { tsdbClient } = useContext(ApiContext);
  const [alertMessage, setAlertMessage] = useState({});

  // const userId = parseInt(useParams().userId);
  const profileId = parseInt(useParams().profileId);

  const fetchWatchLogs = () => {
    setIsLoading(true);
    let field = 'updatedAt';
    let sort = 'desc';
    let filter = {};
    try {
      ({ field, sort } = (queryOptions.sortModel || [{ field: 'updatedAt', sort: 'desc' }])[0]);
    } catch (error) {
      console.log('failed to parse sortModel');
    }
    try {
      const items = queryOptions.filterModel?.items || [];
      if (items.length > 0) {
        items.forEach(item => {
          if (!!item.value) filter = { ...filter, [item.columnField]: item.value }
        });
      }
    } catch (error) {
      console.log('failed to parse filterModel');
    }

    tsdbClient.request(getWatchLogsViewQuery, {
      paging: {
        page: page + 1, pageSize
      },
      sort: { [field]: sort.toLowerCase() === 'desc' ? -1 : 1 },
      clause: {
        hidden: { $ne: true },
        profileId: profileId.toString(), ...Object.keys(filter).reduce(
          (prev, key) => ({
            ...prev,
            [key]: { $like: `%${filter[key]}%` },
          }),
          {},
        )
      },
    }).then((result) => {
      setData(result.watchLogs.results);
      setRowCount(result.watchLogs.totalResults);
      setIsLoading(false);
    }).catch((error) => {
      setAlertMessage({ message: error.message });
    });
  };

  const fetchPreferences = ({ showId, seriesGroupId }) => {
    setIsLoading(true);
    tsdbClient.request(getProfilePreferenceQuery, {
      where: {
        profileId: profileId.toString(), showId, seriesGroupId,
      },
    }).then((result) => {
      result.preferences.sameAsDefault = (JSON.stringify((profile || {}).preferences) === JSON.stringify(result.preferences));
      setPreferenceData(result.preferences);
      setPreferencesOpen(true);
      setIsLoading(false);
    }).catch((error) => {
      setAlertMessage({ message: error.message });
    });
  };

  useEffect(fetchWatchLogs, [tsdbClient, profileId, page, pageSize, queryOptions]);

  const handleSortModelChange = useCallback((sortModel) => {
    // Here you save the data you need from the sort model
    setQueryOptions({ sortModel: [...sortModel] });
  }, []);

  const handleFilterModelChange = useCallback((filterModel) => {
    // Here you save the data you need from the sort model
    setQueryOptions({ filterModel: { ...filterModel } });
  }, []);

  const handleWatchLogPreferenceClick = (params) => {
    console.log(params);
    const { showId, seriesGroupId } = params.row;
    fetchPreferences({ showId, seriesGroupId });
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <div className="viewButton" onClick={() => handleWatchLogPreferenceClick(params)}>View Preferences</div>
          </div>
        );
      },
    },
  ];
  return (
    <div className="datatable embedded">
      {alertMessage.message && <Alert severity={alertMessage.severity || "error"}>{alertMessage.message}</Alert>}
      <DataGrid
        autoHeight
        className="datagrid"
        rows={data}
        rowCount={rowCount}
        loading={isLoading}
        columns={userColumns.concat(actionColumn)}
        pageSize={pageSize}
        rowsPerPageOptions={[10, 20, 50]}
        pagination
        paginationMode="server"
        sortingMode="server"
        filterMode="server"
        onPageChange={(newPage) => setPage(newPage)}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        onSortModelChange={handleSortModelChange}
        onFilterModelChange={handleFilterModelChange}
      />
      <Preferences preferenceData={preferenceData} preferencesOpen={preferencesOpen} handlePreferencesClose={() => setPreferencesOpen(false)} />
    </div>
  );
};

export default WatchLogTable;