import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/home/Home";
import List from "./pages/list/List";
import Login from "./pages/login/Login";
import User from "./pages/user/User";
import Edit from "./pages/edit/Edit";
import { userInputs } from "./fromSource"
import "./style/dark.scss";
import { useContext } from "react";
import { DarkModeContext } from "./context/darkModeContext";
import Profile from "./pages/profile/Profile";
import PrivateRoutes from "./PrivateRoutes";
import { MainLayout } from "./components/MainLayout";
import Braintree from "./pages/iaptools/braintree";
import Amazon from "./pages/iaptools/amazon";
import Roku from "./pages/iaptools/roku";
import Apple from "./pages/iaptools/apple";

function App() {
  const { darkMode } = useContext(DarkModeContext);

  return (
    <div className={darkMode ? "app dark" : "app"}>
      <BrowserRouter>
        <Routes>
          <Route element={<PrivateRoutes />}>
            <Route path="/" element={<MainLayout />}>
              <Route index element={<Home />} />
              <Route path="users">
                <Route index element={<List type="users" />} />
                <Route path=":userId" element={<User />} />
                <Route path=":userId/profile/:profileId" element={<Profile />} />
                <Route path="edit" element={<Edit inputs={userInputs} title="Edit User" />} />
              </Route>
              <Route path="iaptools/amazon">
                <Route index element={<Amazon/>} />
              </Route>
              <Route path="iaptools/apple">
                <Route index element={<Apple/>} />
              </Route>
              <Route path="iaptools/braintree">
                <Route index element={<Braintree/>} />
              </Route>
              <Route path="iaptools/google">
                <Route index element={<Braintree/>} />
              </Route>
              <Route path="iaptools/roku">
                <Route index element={<Roku/>} />
              </Route>
            </Route>
          </Route>
          <Route path="login" element={<Login />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
