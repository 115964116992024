import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { ApiContextProvider } from './context/apiContext';
import { AppStateContextProvider } from './context/appStateContext';
import { DarkModeContextProvider } from './context/darkModeContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode> - causes rendering twice
  <DarkModeContextProvider>
    <AppStateContextProvider>
      <ApiContextProvider>
        <App />
      </ApiContextProvider>
    </AppStateContextProvider>
  </DarkModeContextProvider>
  // </React.StrictMode>
);
