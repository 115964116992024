import "./list.scss"
import UserTable from "../../components/datatables/UserTable"
import Datatable from "../../components/datatables/Datatable"

const List = ({ type }) => {
  return (
    <div className="list">
      <div className="listContainer">
        {type === "users" && <UserTable />}
        {type !== "users" && <Datatable />}
      </div>
    </div>
  )
}

export default List