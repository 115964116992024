import "./chart.scss";
import {
  AreaChart,
  Area,
  XAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { useCallback, useContext, useEffect, useState } from "react";
import { getPlanStatisticsSimpleQuery } from "../../queries";
import { ApiContext } from "../../context/apiContext";
import { Alert } from "@mui/material";
import moment from "moment/moment";

const SignupsSixMonths = ({ aspect, title }) => {
  const [chartPlanStats, setChartPlanStats] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { tsdbClient, accountClient } = useContext(ApiContext);
  const [alertMessage, setAlertMessage] = useState({});

  const fetchPlanStats = useCallback(async ({ startDate, endDate }) => {
    try {
      const result = await accountClient.request(getPlanStatisticsSimpleQuery, {
        startDate: new Date(startDate).toISOString(), endDate: new Date(endDate).toISOString(),
      });
      const { ok, errors, statistics } = result.planStatistics;
      if (!ok) throw Error(errors[0].message);
      const monthName = moment(startDate);
      const FREE = (statistics.find(stat => stat.subscriptionLevelCode === 'FREE') || {}).count || 0;
      const PREMIUM = (statistics.find(stat => stat.subscriptionLevelCode === 'PREMIUM') || {}).count || 0;
      const newEntry = { month: monthName.format("MMMM"), startDate, endDate, FREE, PREMIUM };
      setChartPlanStats(s => [...s, newEntry]);
    } catch (error) {
      setAlertMessage({ message: error.message });
    }
  }, [accountClient]);

  const getDateRanges = (months) => {
    const ranges = [];
    const today = new Date();
    const initialDate = moment(today);
    initialDate.subtract(months, 'month');
    var startDate = moment(initialDate);
    today.setDate(today.getDate() + 1);
    var lastDate = moment(today);

    while (startDate.isBefore(lastDate)) {
      const endDate = moment(startDate);
      endDate.add(1, 'month');
      ranges.push({ startDate: startDate.format("YYYY-MM-01"), endDate: endDate.format("YYYY-MM-01") });
      startDate.add(1, 'month');
    }

    console.log(ranges);
    return ranges;
  }

  useEffect(() => {
    setIsLoading(true);
    setChartPlanStats([]);
    const MONTHS_OF_STATS = 6;
    const dateRanges = getDateRanges(MONTHS_OF_STATS - 1);
    console.log(dateRanges);
    fetchPlanStats(dateRanges[0])
      .then(() => fetchPlanStats(dateRanges[1]))
      .then(() => fetchPlanStats(dateRanges[2]))
      .then(() => fetchPlanStats(dateRanges[3]))
      .then(() => fetchPlanStats(dateRanges[4]))
      .then(() => fetchPlanStats(dateRanges[5]))
      .then(() => {
        setIsLoading(false);
        // console.log('done loading plan stats', data);
      });
    // fetchPlanStats("2021-12-31 03:44:20", "2022-01-31 03:44:20");
  }, [fetchPlanStats, accountClient]);


  return (
    <div className="chart">
      <div className="title">{title}</div>
      {alertMessage.message && <Alert severity={alertMessage.severity || "error"}>{alertMessage.message}</Alert>}
      <ResponsiveContainer width="100%" aspect={aspect} maxHeight={335}>
        <AreaChart
          width={730}
          height={250}
          data={chartPlanStats}
          margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
        >
          <defs>
            <linearGradient id="free" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="premium" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#d584d8" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#d584d8" stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis dataKey="month" stroke="gray" />
          <CartesianGrid strokeDasharray="3 3" className="chartGrid" />
          <Tooltip />
          <Area
            type="monotone"
            dataKey="FREE"
            stroke="#8884d8"
            fillOpacity={1}
            fill="url(#free)"
          />
          <Area
            type="monotone"
            dataKey="PREMIUM"
            stroke="#d584d8"
            fillOpacity={1}
            fill="url(#premium)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default SignupsSixMonths;