import { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom"
import { AppStateContext } from "./context/appStateContext";
import { ApiContext } from "./context/apiContext"
const { REACT_APP_TOKEN_NAME } = process.env;

const PrivateRoutes = () => {
    const token = localStorage.getItem(REACT_APP_TOKEN_NAME)
    const { logout } = useContext(ApiContext);
    const { appState } = useContext(AppStateContext);
    let userId = null;
    let activeProfileId = null;
    try {
        const decodedToken = JSON.parse(atob(token.split('.')[1]));
        if (!decodedToken) throw new Error('invalid refresh token');
        ({ user: { id: userId, activeProfileId } } = decodedToken);
        if (![2, 3, 94, 3314, 30364, 52493].includes(userId)) {
            userId = null;
            throw Error('Access denied');
        }
        if (!appState?.adminUser) throw Error('Reauthentication required');
    } catch (e) {
        console.log('Invalid login');
        logout();
    }
    return ((userId && activeProfileId) ? <Outlet /> : <Navigate to="/login" />)
}

export default PrivateRoutes;
