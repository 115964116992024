import { createContext, useContext, useState } from "react";
import { ApiContext } from "./apiContext"

const { REACT_APP_STATE_NAME } = process.env;

const INITIAL_STATE = (() => {
    try {
        // restore state if it exists after a page load
        return JSON.parse(atob(localStorage.getItem(REACT_APP_STATE_NAME)));
    } catch (error) {
        return null;
    }
})();

export const AppStateContext = createContext(INITIAL_STATE);

export const AppStateContextProvider = ({ children }) => {
    const { addAuthHeaders } = useContext(ApiContext);

    // Whenever the adminUser is updated, reset the authentication header tokens for the apis
    const reinitApiAuthHeaders = (state) => {
        if (state && Object.keys(state).includes("adminUser")) addAuthHeaders();
    }
    
    const [appState, setAppState] = useState(INITIAL_STATE);
    reinitApiAuthHeaders(appState);
    const setAppStatePersist = (state) => {
        setAppState(state);
        localStorage.setItem(REACT_APP_STATE_NAME, btoa(JSON.stringify(state)));
        reinitApiAuthHeaders(state);
    }
    return (
        <AppStateContext.Provider value={{ appState, setAppState: setAppStatePersist }}>
            {children}
        </AppStateContext.Provider>
    );
};