import "./featured.scss";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import { useCallback, useContext, useEffect, useState } from "react";
import { ApiContext } from "../../context/apiContext";
import { getPlanStatisticsSimpleQuery, getWatchLogFSQ } from "../../queries";
import { Alert } from "@mui/material";

const activeUsersFSQ = {
  "operation": "aggregate",
  "clause": [
    {
      "$match": {
        "updatedAt": {
          // these dates will get replaced when fetched
          "$gt": "2023-01-01T00:00:00z",
          "$lt": "2023-02-01T00:00:00z"
        }
      }
    }, {
      "$project": {
        "profileId": 1,
      },
    }, {
      "$group": {
        "_id": {
          "profileId": "$profileId",
        },
      },
    }, {
      "$count":
        "activeUsers",
    },
  ]
};

const Featured = () => {
  const [statsData, setStatsData] = useState({});
  const [activeUsersCount, setActiveUsersCount] = useState(0);
  const [previousActiveUsersCount, setPreviousActiveUsersCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const { tsdbClient, accountClient } = useContext(ApiContext);
  const [alertMessage, setAlertMessage] = useState({});
  // const testDate = new Date();
  // testDate.setMonth(testDate.getMonth() - 3);

  const fetchPlanStats = useCallback(async ({ startDate, endDate }) => {
    try {
      const result = await accountClient.request(getPlanStatisticsSimpleQuery, {
        startDate: startDate.toISOString(), endDate: endDate.toISOString(),
      });
      const { ok, errors, statistics } = result.planStatistics;
      if (!ok) throw Error(errors[0].message);
      const statDayCount = Math.max(1, (endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24));
      const FREE = (statistics.find(stat => stat.subscriptionLevelCode === 'FREE') || {}).count || 0;
      const PREMIUM = (statistics.find(stat => stat.subscriptionLevelCode === 'PREMIUM') || {}).count || 0;
      const newStatsData = {};
      if (statDayCount > 1) {
        // these are the averages
        newStatsData.FREEAvg = FREE / statDayCount;
        newStatsData.PREMIUMAvg = PREMIUM / statDayCount;
        newStatsData.TotalAvg = (FREE + PREMIUM) / statDayCount;
        newStatsData.days = Math.round(statDayCount);
      } else {
        newStatsData.FREEToday = FREE;
        newStatsData.PREMIUMToday = PREMIUM;
        newStatsData.TotalToday = FREE + PREMIUM;
        newStatsData.todayElapsedHours = Math.abs(new Date(/* testDate */) - startDate) / 36e5;
      }
      console.log(newStatsData);
      setStatsData(s => ({ ...s, ...newStatsData }));
    } catch (error) {
      setAlertMessage({ message: error.message });
    }
  }, [accountClient]);

  const fetcActiveUsers = useCallback(async (startDate, endDate) => {
    try {
      const matchIdx = activeUsersFSQ.clause.findIndex(c => c['$match']);
      activeUsersFSQ.clause[matchIdx]["$match"].updatedAt = {
        "$gt": startDate.toISOString(),
        "$lt": endDate.toISOString()
      };
      const result = await tsdbClient.request(getWatchLogFSQ, activeUsersFSQ);
      let res;
      try {
        res = JSON.parse(result.getWatchLogFreeStyle);
      } catch (error) { throw Error(`${error.message}: ${result}`); }
      let totalActiveUsers = 0;
      res.forEach(item => { totalActiveUsers += item.activeUsers; });
      return totalActiveUsers;
    } catch (error) {
      setAlertMessage({ message: error.message });
    }
  }, [tsdbClient]);

  useEffect(() => {
    setIsLoading(true);
    setStatsData([]);
    const sevenDaysStart = new Date(); //testDate);
    // sevenDaysStart.setHours(24, 0, 0, 0);
    sevenDaysStart.setDate(sevenDaysStart.getDate() - 7);
    const todayStart = new Date(); // testDate);
    todayStart.setDate(todayStart.getDate() - 1);
    // todayStart.setHours(0, 0, 0, 0);
    const todayEnd = new Date();
    // todayEnd.setDate(todayStart.getDate() + 1);
    fetchPlanStats({ startDate: sevenDaysStart, endDate: todayStart })
      .then(() => fetchPlanStats({ startDate: todayStart, endDate: todayEnd }))
      .then(() => {
        setIsLoading(false);
        // console.log('done loading plan stats', statsData);
      });

    const today = new Date();
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
    fetcActiveUsers(thirtyDaysAgo, today).then((activeUsers) => {
      setActiveUsersCount(activeUsers);
      const sixtyDaysAgo = new Date(thirtyDaysAgo);
      sixtyDaysAgo.setDate(sixtyDaysAgo.getDate() - 30);
      fetcActiveUsers(sixtyDaysAgo, thirtyDaysAgo).then((activeUsers) => {
        setPreviousActiveUsersCount(activeUsers);
      })
    });
    // fetchPlanStats("2021-12-31 03:44:20", "2022-01-31 03:44:20");
  }, [fetcActiveUsers, fetchPlanStats]);

  const calcGoalPcnt = () => {
    if (!statsData?.TotalToday) return 0
    return Math.round(statsData?.TotalToday / statsData?.TotalAvg * 100);
  }

  const TierStat = ({ title, planCode }) => {
    const today = (statsData || {})[`${planCode}Today`] || "--";
    const avg = (statsData || {})[`${planCode}Avg`] || "--";
    const pcnt = today === "--" || avg === "--" ? 100 : Math.round(today / avg * 100) - 100;
    return (
      <>
        <div className="itemTitle">{title}</div>
        <div className="resultAmount">{today}</div>
        <div className={`itemResult ${pcnt < 0 ? 'negative' : 'positive'}`}>
          {pcnt < 0 && <KeyboardArrowDownIcon fontSize="small" />}
          {pcnt > 0 && <KeyboardArrowUpOutlinedIcon fontSize="small" />}
          <div className="resultAmount">{pcnt}% from {avg === "--" ? avg : Math.round(avg)}</div>
        </div>
      </>
    );
  };

  const ActiveUserStat = () => {
    const pcnt = activeUsersCount === 0 || previousActiveUsersCount === 0 ? 100 : Math.round(activeUsersCount / previousActiveUsersCount * 100) - 100;
    return (
      <>
        <div className="itemTitle">Active Users (30d)</div>
        <div className="resultAmount">{activeUsersCount}</div>
        <div className={`itemResult ${pcnt < 0 ? 'negative' : 'positive'}`}>
          {pcnt < 0 && <KeyboardArrowDownIcon fontSize="small" />}
          {pcnt > 0 && <KeyboardArrowUpOutlinedIcon fontSize="small" />}
          <div className="resultAmount">{pcnt}% from {previousActiveUsersCount}</div>
        </div>
      </>
    )
  }

  return (
    <div className="featured">
      <div className="top">
        <h1 className="title">Daily Signups</h1>
        <MoreVertIcon fontSize="small" />
      </div>
      {alertMessage.message && <Alert severity={alertMessage.severity || "error"}>{alertMessage.message}</Alert>}
      <div className="bottom">
        <div className="featuredChart">
          <CircularProgressbar value={calcGoalPcnt()} text={`${calcGoalPcnt()}%`} strokeWidth={5} />
        </div>
        <p className="title">User Goal for Today</p>
        <p className="amount">{statsData?.TotalToday ? `${statsData?.TotalToday} of ${Math.round(statsData?.TotalAvg)}` : "-- of --"}</p>
        <p className="desc">
          New user signups in the last 24 hours<br />compared to the average from last 7 days
        </p>
        <div className="summary">
          <div className="item">
            <TierStat title="Free Tier" planCode="FREE" />
          </div>
          <div className="item">
            <TierStat title="Paid Tier" planCode="PREMIUM" />
          </div>
          <div className="item">
            <ActiveUserStat />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Featured;