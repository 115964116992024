import "./table.scss";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { createShortenedDateTime, getAvatarUrl } from "../../utils/utils";
import { Link, useNavigate } from "react-router-dom";

const ProfileTable = ({ rows }) => {
  const navigate = useNavigate();
  const handleRowClick = (profileId, rowMeta) => {
    // console.log(extra);
    if (!Array.from(Array.from(rowMeta?.target?.classList) || []).find(cl => cl.toLowerCase().indexOf('button') > -1)) {
      navigate(`./profile/${profileId}`);
    }
  }

  return (
    <TableContainer component={Paper} className="table">
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className="tableCell">Id</TableCell>
            <TableCell className="tableCell">Name</TableCell>
            <TableCell className="tableCell">Content PIN</TableCell>
            <TableCell className="tableCell">Filter PIN</TableCell>
            <TableCell className="tableCell">Secured PIN</TableCell>
            <TableCell className="tableCell">Updated</TableCell>
            <TableCell className="tableCell">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(rows || []).map((row) => (
            <TableRow key={row.id} onClick={(e) => handleRowClick(row.id, e)}>
              <TableCell className="tableCell">
                <Link to={`./profile/${row.id}`} target="_blank" rel="noreferrer" style={{ textDecoration: "none" }}>
                  <button className="linkButton">{row.id}</button>
                </Link>
              </TableCell>
              <TableCell className="tableCell">
                <div className="cellWrapper">
                  <img src={getAvatarUrl({ firstName: row.name, avatarUrl: row.avatarUrl })} alt="" className="image" />
                  {row.name}
                </div>
              </TableCell>
              <TableCell className="tableCell">{row.contentPINRestricted}</TableCell>
              <TableCell className="tableCell">{row.filterChangesNeedPIN ? 'True' : 'False'}</TableCell>
              <TableCell className="tableCell">{row.securedByPIN ? 'True' : 'False'}</TableCell>
              <TableCell className="tableCell">{createShortenedDateTime(row.updatedAt)}</TableCell>
              <TableCell className="tableCell">
                <div className=" cellAction">
                  <Link to={`./profile/${row.id}`} className="viewButton" style={{ textDecoration: "none" }} target="_blank" rel="noopener noreferrer">
                    <div className="viewButton">View</div>
                  </Link>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ProfileTable;