import "./user.scss";
import { Alert, Box, Tab, Tabs } from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { ApiContext } from "../../context/apiContext"
import { Link, useNavigate, useParams } from 'react-router-dom'
import { getProviderActivationCodesQuery, getUserDetailQuery, getUserQuery } from "../../queries";
import { CancelPlanControl, ConfirmDialog, deleteUser, getAvatarUrl } from "../../utils/utils";
import ProviderTable from "../../components/table/ProviderTable";
import ProfileTable from "../../components/table/ProfileTable";
import WatchedByProvider from "../../components/chart/WatchedByProvider";

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 1 }}>
                    {children}
                </Box>
            )}
        </div>
    );
};

const User = () => {
    const [state, setState] = useState({ user: {}, isLoading: true });
    const [activeTabIdx, setActiveTabIdx] = useState(0);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [alertMessage, setAlertMessage] = useState({});
    const { accountClient, authClient } = useContext(ApiContext);
    const navigate = useNavigate();

    // console.log(userId);
    const userId = parseInt(useParams().userId);

    const { user } = state;

    const syncIAP = () => {
        accountClient.request(`mutation { syncCustomerSubscriptions(customerId: "${user.iapUserId}") { message } }`).then((result) => {
            console.log(result);
            const { message } = result.syncCustomerSubscriptions[0] || { message: 'No message' };
            if (message.startsWith('Sync status')) setAlertMessage({ message: "Customer Synced with IAP Gateway", severity: 'info' });
            else setAlertMessage({ message });
            setTimeout(() => setAlertMessage({ message: null }), 5000);
            fetchUser(true);
        });
    };

    const fetchUser = useCallback(async (force) => {
        if (state.user.userId === userId && !force) return;
        state.user.userId = userId; // do this to avoid hitting the server repeatedly on load
        const queue = [];
        queue.push(() => accountClient.request(getUserDetailQuery, {
            userId,
        }).then((result) => ({ data: result.user })))
        queue.push(() => authClient.request(getUserQuery, {
            id: userId,
        }).then((result) => ({ data: result.user })));
        queue.push(() => accountClient.request(getProviderActivationCodesQuery, { userId }).then((result) => ({
            data: result.providerActivationCodes,
        })));

        const user = { data: {} };
        await new Promise((resolve) => {
            Promise.all(queue.map(q => q()))
                .then((results) => {
                    user.data = results[0].data;
                    user.data = { ...user.data, ...results[1].data };
                    // fix up our userdetail id and user id to avoid confusion
                    user.data.userDetailId = user.data.id;
                    user.data.connectedProviders = results[2].data.providerActivationCodes;
                    // add in profile data
                    const { Profiles: profiles } = user.data;
                    // set an avatar to the first profile
                    const { firstName, lastName } = user.data;
                    user.data.mainProfileAvatar = getAvatarUrl({ firstName, lastName, avatarUrl: (profiles || []).sort((a, b) => a.id - b.id)[0]?.avatarUrl });
                    // console.log(user.data.mainProfileAvatar);
                    // const pqueue = [];
                    // (profiles || []).forEach((profile) => {
                    //     pqueue.push(tsdbClient.request(getProfilePreferenceQuery, { where: { profileId: profile.id.toString() } }));
                    // });
                    // Promise.all(pqueue).then((profilePrefs) => {
                    //     profilePrefs.forEach(({ Preferences: pref }) => {
                    //         const idx = user.data.Profiles.findIndex((p) => p.id.toString() === pref.profileId);
                    //         if (idx > -1) user.data.Profiles[idx].Preferences = pref;
                    //     });
                    resolve(user);
                    // });
                }).catch((error) => {
                    setAlertMessage({ message: error.message });
                });
        });
        setState({ ...state, isLoading: false, user: user.data });
        console.log(user.data);
        return user.data;
    }, [accountClient, authClient, userId, state]);

    useEffect(() => {
        fetchUser();
    }, [fetchUser]);

    const handleTabChange = (event, newValue) => {
        setActiveTabIdx(newValue);
    };

    const renderPlanField = () => {
        let code = user.activeSubscriptionLevelCode;
        if (user.subscriptionLevelCode !== user.activeSubscriptionLevelCode) code += ` (going to ${user.subscriptionLevelCode})`;
        return code;
    }

    const renderPaymentMethod = () => {
        let paymentMethod = user.paymentMethod
        if (paymentMethod === 'IAP') paymentMethod = user.iapPlatform || paymentMethod;
        else return (
            <Link to={`https://www.braintreegateway.com/merchants/bn2nxfvnncf87rbn/customers/${user.iapUserId}`}  target="_blank" rel="noreferrer">
                {user.braintreePaymentMethod || paymentMethod}
            </Link>
        );
        return paymentMethod || 'N/A';
    };

    const handleDelete = () => {
        deleteUser({ authClient, user })
            .then((res) => {
                console.log('user deleted', res);
                navigate("/users");
            })
            .catch((error) => {
                // console.error('user not deleted', error);
                setAlertMessage({ message: error.message });
                setTimeout(() => setAlertMessage({ message: null }), 5000);
            });
    };

    return (
        <div className="single">
            <div className="singleContainer">
                <div className="top">
                    <div className="left">
                        <div className="editButton">Edit</div>
                        <h1 className="title">User Information</h1>
                        <div className="item">
                            <img
                                src={user.mainProfileAvatar}
                                alt=""
                                className="itemImg"
                            />
                            <div className="details">
                                <h1 className="itemTitle">{user.firstName} {user.lastName} </h1>
                                <div className="detailItem">
                                    <span className="itemKey">Email:</span>
                                    <span className="itemValue">{user.email}</span>
                                </div>
                                <div className="detailItem">
                                    <span className="itemKey">Plan:</span>
                                    <span className="itemValue">{renderPlanField()}</span>
                                </div>
                                <div className="detailItem">
                                    <span className="itemKey">Payment:</span>
                                    <span className="itemValue">{renderPaymentMethod()}</span>
                                </div>
                            </div>
                        </div>
                        <div className="actionButtons">
                            <button
                                className="syncIAPButton"
                                disabled={!user.iapUserId}
                                onClick={() => {
                                    syncIAP();
                                }}
                            >
                                IAP Sync
                            </button>
                            <CancelPlanControl user={user} />
                            <button
                                className="deleteButton"
                                onClick={() => {
                                    console.log('hey');
                                    setConfirmDelete(true);
                                }}
                            >
                                Delete
                            </button>
                            <ConfirmDialog
                                title="Delete User?"
                                open={confirmDelete}
                                setOpen={setConfirmDelete}
                                onConfirm={handleDelete}
                            >
                                Are you sure you want to delete this user?
                            </ConfirmDialog>
                        </div>
                    </div>
                    <div className="right">
                        <WatchedByProvider profiles={user.Profiles} aspect={3 / 1} title="Watched By Provider" />
                    </div>
                </div>
                {alertMessage.message && <Alert severity={alertMessage.severity || "error"}>{alertMessage.message}</Alert>}
                <div className="bottom">
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={activeTabIdx} onChange={handleTabChange} aria-label="basic tabs example">
                            <Tab label="Providers" />
                            <Tab label="Profiles" />
                        </Tabs>
                    </Box>
                    <TabPanel value={activeTabIdx} index={0}>
                        {/* <h1 className="title">Providers</h1> */}
                        <ProviderTable rows={user.connectedProviders} />
                    </TabPanel>
                    <TabPanel value={activeTabIdx} index={1}>
                        {/* <h1 className="title">Profiles</h1> */}
                        <ProfileTable rows={user.Profiles} />
                    </TabPanel>
                </div>
            </div>
        </div>
    );
};

export default User;