import { gql } from "graphql-request";

const userDetailFields = `
  id
  userId
  firstName
  lastName
  email
  subscriptionLevelCode
  activeSubscriptionLevelCode
  signupCompletedOn
  createdAt
  startOfBillingCycle
  currentBillingCycleStart
  currentBillingCycleEnd
  paymentMethod
  iapPlatform
  iapUserId
`;

export const getUserDetailQuery = gql`
    query ($userId: Int!) {
        user: getUserDetail(userId: $userId) {
            ${userDetailFields}
        }
    }
`;

export const getUserDetailsQuery = gql`
  query($pageSize: Int!, $page: Int!, $orderBy: JSON, $where: JSON) {
    users: getUserDetails(pageSize: $pageSize, page: $page, orderBy: $orderBy, where: $where) {
      results {
        ${userDetailFields}
      }
      page
      totalPages
      totalResults
    }
  }
`;

export const getUserQuery = gql`
    query ($id: Int!) {
        user: getUser(id: $id) {
            pin
            Profiles {
                id
                name
                avatarUrl
                securedByPIN
                contentPINRestricted
                filterChangesNeedPIN
                Permissions {
                    name
                    accessLevel
                }
                createdAt
                updatedAt
            }
        }
    }
`;

export const getProviderActivationCodesQuery = gql`
  query ($userId: Int) {
    providerActivationCodes: getProviderActivationCodes(userId: $userId) {
        ok
        errors {
            message
        }
        providerActivationCodes {
            providerCode
            activelyBeingPolled
            activationCode
            tokens
            updatedAt
        }
    }
  }
`;

export const getProfilePreferenceQuery = gql`
    query ($where: PreferenceInput!) {
        preferences: getProfilePreferences(where: $where) {
            profileId
            updatedAt
            jsonPreferences
        }
    }
`;

export const getProfileQuery = gql`
  query ($id: Int!) {
      profile: getProfile(id: $id) {
        id
        name
        avatarUrl
        securedByPIN
        contentPINRestricted
        filterChangesNeedPIN
        Permissions {
          name
          accessLevel
        }
        createdAt
        updatedAt
      }
  }
`;

export const getWatchLogsViewQuery = gql`
  query($clause: JSON, $sort: JSON, $paging: PagingInput) {
    watchLogs: getWatchLogsView(clause: $clause, sort:$sort, paging: $paging) {
      results {
        id
        showId
        seriesGroupId
        seasonNumber
        episodeNumber
        exosite {
          siteName
          siteId
        }
        hidden
        Show {
          title
        }
        SeriesGroup {
          title
        }
        createdVideoTime
        longestVideoTime
        updatedVideoTime
        videoDuration
        createdAt
        updatedAt
      }
      page
      totalPages
      totalResults
    }
  }
`;

export const getPlanStatisticsSimpleQuery = gql`
  query($startDate: DateTime, $endDate: DateTime) {
    planStatistics: getPlanStatisticsSimple(startDate:$startDate, endDate:$endDate) {
      statistics {
        subscriptionLevelCode
        count
      }
      ok
      errors {
        message
      }
    }
  }
`;

export const getWatchLogFSQ = gql`
  query($clause: JSON!, $operation: String!) {
    getWatchLogFreeStyle(operation: $operation, clause: $clause)
  }
`;


export const watchedByProviderFSQ = {
  "operation": "aggregate",
  "clause": [
    {
      "$match": {
        "updatedAt": {
          // these dates will get replaced when fetched
          "$gt": "2023-01-01T00:00:00z",
          "$lt": "2023-02-01T00:00:00z"
        },
        "exosite.siteName": { "$nin": [null, "provider"] }
        // "profileId": { "$in": ["2"] }, // will be replaced when fetched
      }
    },
    {
      "$project": {
        "profileId": 1,
        "exosite.siteName": 1
      }
    },
    {
      "$group": {
        "_id": {
          "siteName": "$exosite.siteName",
          "profileId": "$profileId"
        },
        "count": {
          "$sum": 1
        }
      }
    }
  ]
};
