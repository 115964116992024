import { GraphQLClient } from "graphql-request";
import { createContext } from "react";

const { NODE_ENV } = process.env;
console.log('NODE_ENV:', NODE_ENV);
const {
  REACT_APP_AUTH_API, REACT_APP_TOKEN_NAME, REACT_APP_REFRESH_TOKEN_NAME, REACT_APP_STATE_NAME,
  REACT_APP_TOKEN_NAME_API, REACT_APP_REFRESH_TOKEN_NAME_API, REACT_APP_SHOWDB_API_KEY,
  REACT_APP_ACCOUNT_API, REACT_APP_TSDB_API
} = process.env;

if (!REACT_APP_AUTH_API) console.error('########### ERROR: AUTH_API_URL undefined! ##########');

const authClient = new GraphQLClient(REACT_APP_AUTH_API, { timeout: 300000 });
const accountClient = new GraphQLClient(REACT_APP_ACCOUNT_API, { timeout: 300000 });
const tsdbClient = new GraphQLClient(REACT_APP_TSDB_API, { timeout: 300000 });

const addAuthHeaders = () => {
  authClient.setHeader(REACT_APP_TOKEN_NAME_API, localStorage.getItem(REACT_APP_TOKEN_NAME));
  authClient.setHeader(REACT_APP_REFRESH_TOKEN_NAME_API, localStorage.getItem(REACT_APP_REFRESH_TOKEN_NAME));
  accountClient.setHeader(REACT_APP_TOKEN_NAME_API, localStorage.getItem(REACT_APP_TOKEN_NAME));
  accountClient.setHeader(REACT_APP_REFRESH_TOKEN_NAME_API, localStorage.getItem(REACT_APP_REFRESH_TOKEN_NAME));
  tsdbClient.setHeader('api-key', REACT_APP_SHOWDB_API_KEY);
  tsdbClient.setHeader(REACT_APP_TOKEN_NAME_API, localStorage.getItem(REACT_APP_TOKEN_NAME));
  tsdbClient.setHeader(REACT_APP_REFRESH_TOKEN_NAME_API, localStorage.getItem(REACT_APP_REFRESH_TOKEN_NAME));
};

const INITIAL_STATE = {
  authClient,
  accountClient,
  tsdbClient,
  logout: () => {
    localStorage.removeItem(REACT_APP_TOKEN_NAME);
    localStorage.removeItem(REACT_APP_REFRESH_TOKEN_NAME);
    localStorage.removeItem(REACT_APP_STATE_NAME);
    return Promise.resolve();
  },
  addAuthHeaders,
};

export const ApiContext = createContext(INITIAL_STATE);

export const ApiContextProvider = ({ children }) => {
  // const [state, dispatch] = useState(INITIAL_STATE);

  return (
    <ApiContext.Provider value={INITIAL_STATE}>
      {children}
    </ApiContext.Provider>
  );
};