import React, { useEffect } from 'react';
import "./home.scss";
import Widget from '../../components/widgets/Widget';
import Featured from '../../components/featured/Featured';
import SignupsSixMonths from '../../components/chart/SignupsSixMonths';
import UserTable from '../../components/datatables/UserTable';
import { useNavigate } from 'react-router-dom';

const Home = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const checkIfWasRedirected = () => {
            /* Check for redirect in search string only if pathname does not start with one of the other site's prefix */
            if (window.location.search) {
                const urlParams = new URLSearchParams(window.location.search);
                const myParam = urlParams.get('redirectTo');
                if (window.location.pathname !== '/404') return myParam;
            }
            return null;
        };

        const redirectTo = checkIfWasRedirected();
        console.log('found redirect: ', redirectTo);
        if (redirectTo) navigate(redirectTo);
    });

    return (
        <div className="home">
            <div className='homeContainer'>
                <div className="widgets">
                    <Widget type="user" />
                    <Widget type="order" />
                    <Widget type="watched" />
                    <Widget type="earning" />
                </div>
                <div className="charts">
                    <Featured />
                    <SignupsSixMonths title="Last 6 Months (Signups)" aspect={2 / 1} />
                </div>
                <div className="listContainer">
                    <UserTable title="Recent Signups" />
                </div>
            </div>
        </div>
    )
}

export default Home;