import "./table.scss";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { createShortenedDateTime } from "../../utils/utils";

const ProviderTable = ({ rows }) => {
  return (
    <TableContainer component={Paper} className="table">
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className="tableCell">Provider</TableCell>
            <TableCell className="tableCell">Connected</TableCell>
            <TableCell className="tableCell">Polling</TableCell>
            <TableCell className="tableCell">Updated</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(rows || []).map((row) => (
            <TableRow key={row.providerCode}>
              <TableCell className="tableCell">{row.providerCode}</TableCell>
              <TableCell className="tableCell">
                <span className={`status ${row.tokens ? 'Approved' : 'Pending'}`}>{row.tokens ? 'Connected' : 'Pending'}</span>
              </TableCell>
              <TableCell className="tableCell">{row.activelyBeingPolled ? 'True' : 'False'}</TableCell>
              <TableCell className="tableCell">{createShortenedDateTime(row.updatedAt)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ProviderTable;