import "./datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { Link, useNavigate } from "react-router-dom";
import { useCallback, useContext, useEffect, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { ApiContext } from "../../context/apiContext"
import { getUserDetailsQuery } from "../../queries";
import { Alert } from "@mui/material";
import { createShortenedDateTime } from "../../utils/utils";

const userColumns = [
  {
    field: "userId",
    headerName: "ID",
    width: 70,
    filterable: false,
    renderCell: (params) => {
      return <a href={`./users/${params.row.userId}`} target="_blank" rel="noreferrer" className="viewButton">{params.row.userId}</a>;
    },
  },
  {
    field: "firstName",
    headerName: "First",
    width: 230,
  }, {
    field: "lastName",
    headerName: "Last",
    width: 230,
  }, {
    field: "email",
    headerName: "Email",
    width: 230,
  }, {
    field: "subscriptionLevelCode",
    headerName: "Plan",
    width: 120,
    sortable: false,
    renderCell: (params) => {
      let code = params.row.activeSubscriptionLevelCode;
      let toCode = params.row.subscriptionLevelCode;
      return (
        <div className={`cellWithStatus ${code}`}>
          {code}
          {(
            code !== 'FREE' && toCode === 'FREE' &&
            <KeyboardArrowDownIcon fontSize="small" style={{ color: 'red', verticalAlign: 'middle' }} />
          )}
        </div>
      );
    },
  }, {
    field: "paymentMethod",
    headerName: "Pay Method",
    width: 120,
    sortable: false,
    filterable: false,
    renderCell: (params) => {
      let paymentMethod = params.row.paymentMethod
      if (paymentMethod === 'IAP') paymentMethod = params.row.iapPlatform || paymentMethod;
      return paymentMethod;
    },
  }, {
    field: "createdAt",
    headerName: "Created",
    width: 180,
    filterable: false,
    renderCell: (params) => {
      const createdAt = createShortenedDateTime(params.row.createdAt);
      return createdAt;
    },
  }
];

const UserTable = ({ title }) => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [rowCount, setRowCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [queryOptions, setQueryOptions] = useState({});
  const { accountClient } = useContext(ApiContext);
  const [alertMessage, setAlertMessage] = useState({});
  const navigate = useNavigate();

  const fetchUsers = () => {
    setIsLoading(true);
    let field = 'id';
    let sort = 'asc';
    let filter = {};
    try {
      ({ field, sort } = (queryOptions.sortModel || [{ field: 'id', sort: 'desc' }])[0]);
    } catch (error) {
      console.log('failed to parse sortModel');
    }
    try {
      const items = queryOptions.filterModel?.items || [];
      if (items.length > 0) {
        items.forEach(item => {
          if (!!item.value) filter = { ...filter, [item.columnField]: item.value }
        });
      }
    } catch (error) {
      console.log('failed to parse filterModel');
    }

    accountClient.request(getUserDetailsQuery, {
      pageSize,
      page: page + 1,
      orderBy: [[field, sort.toLowerCase()]],
      where: Object.keys(filter).reduce(
        (prev, key) => ({
          ...prev,
          [key]: { $like: `%${filter[key]}%` },
        }),
        {},
      ),
    }).then((result) => {
      setData(result.users.results);
      setRowCount(result.users.totalResults);
      setIsLoading(false);
    }).catch((error) => {
      setAlertMessage({ message: error.message });
    });
  };

  useEffect(fetchUsers, [accountClient, page, pageSize, queryOptions]);
  // subscribe to the navbar search
  const onNavFilter = useCallback(({ detail: { email } }) => {
    setQueryOptions((s) => ({ ...s, filterModel: { items: [{ columnField: "email", value: email }] } }));
  }, []);
  useEffect(() => {
    document.addEventListener("emailSearch", onNavFilter);
    return () => {
      document.removeEventListener("emailSearch", onNavFilter);
    }
  }, [onNavFilter]);

  const handleSortModelChange = useCallback((sortModel) => {
    // Here you save the data you need from the sort model
    setQueryOptions({ sortModel: [...sortModel] });
  }, []);

  const handleFilterModelChange = useCallback((filterModel) => {
    // Here you save the data you need from the sort model
    setQueryOptions({ filterModel: { ...filterModel } });
  }, []);


  const handleRowClick = (user, rowMeta) => {
    // console.log(rowMeta.target);
    // if the user clicked the button or link, then it will openin new window
    // so don't change pages on this tab
    if (
      user?.row?.userId
      && !(Array.from(rowMeta?.target?.classList) || []).find(cl => cl.toLowerCase().indexOf('button') > -1)
    ) {
      navigate(`/users/${user.row.userId}`);
    }
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link to={`/users/${params.row.userId}`} target="_blank" rel="noreferrer" style={{ textDecoration: "none" }}>
              <div className="viewButton">View</div>
            </Link>
          </div>
        );
      },
    },
  ];
  return (
    <div className="datatable">
      <div className="datatableTitle">
        {title || 'Enjoy Users'}
      </div>
      {alertMessage.message && <Alert severity={alertMessage.severity || "error"}>{alertMessage.message}</Alert>}
      <DataGrid
        autoHeight
        className="datagrid"
        rows={data}
        rowCount={rowCount}
        loading={isLoading}
        columns={userColumns.concat(actionColumn)}
        pageSize={pageSize}
        rowsPerPageOptions={[10, 20, 50]}
        pagination
        paginationMode="server"
        sortingMode="server"
        filterMode="server"
        onPageChange={(newPage) => setPage(newPage)}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        onSortModelChange={handleSortModelChange}
        onFilterModelChange={handleFilterModelChange}
        onRowClick={handleRowClick}
      />
    </div>
  );
};

export default UserTable;