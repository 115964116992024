
import { Alert, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel } from "@mui/material";
import { gql } from "graphql-request";
import { useContext, useState } from "react";
import { ApiContext } from "../context/apiContext";

const cancelSubscription = async ({ accountClient, userId, firstName, email, sendEmail }) => {
    const setPlanMutation = gql`
    mutation ($userId: Int!, $planCode: String!, $iapPlatform: String, $iapUserId: String, $iapReceiptId: String) {
        setUserSubscriptionLevelByCode(
            userId: $userId,
            planCode: $planCode,
            iapPlatform: $iapPlatform,
            iapUserId: $iapUserId,
            iapReceiptId: $iapReceiptId
        ) {
            ok
            email
            errors {
                path
                message
            }
        }
    }
`;
    const res = await (accountClient.request(setPlanMutation, { userId, planCode: 'FREE' }));
    // if send email checked, then do that
    if (sendEmail) {
        const sendEmailMutation = gql`
          mutation($emailData: JSON!) {
              sendEmail: contactUs(emailData: $emailData) {
                  ok
                  errors {
                  message
                  }
              }
          }
        `;
        const sendEmailRes = await accountClient.request(sendEmailMutation, {
            emailData: {
                from: 'support@enjoymoviesyourway.com',
                to: email,
                subject: 'Enjoy Movies Your Way switched to free plan...',
                // eslint-disable-next-line max-len
                message: `Hi ${firstName},\n\nWe were unable to renew your PREMIUM Enjoy subscription using the payment method on file. If you would like to continue using the paid plan, simply login to www.enjoymoviesyourway.com, and in the Manage Account page you can update your payment method and plan.\n\nThanks for using Enjoy! We hope you find our service helpful.\n\nSincerely,\nThe Enjoy Team\nsupport@enjoymoviesyourway.com\n`.replace(/\n/g, '<br />'),
                template: 'past-due-switched-free-email',
            },
        });
        return sendEmailRes.sendEmail;
    }
    return res.setUserSubscriptionLevelByCode;
};

export const CancelPlanControl = ({ user }) => {
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [sendEmail, setSendEmail] = useState(true);
    const [message, setMessage] = useState(null);
    const { accountClient } = useContext(ApiContext);
    // console.log('useRecordContext', record);

    const onCancelPlan = (event) => {
        // console.log(event);
        setConfirmOpen(true);
    };
    const onConfirm = async () => {
        setProcessing(true);
        // do it
        const { ok, errors } = await cancelSubscription({
            accountClient, userId: user.userId, firstName: user.firstName, email: user.email, sendEmail,
        });
        if (!ok) {
            setMessage({ message: errors ? errors[0].message : 'Unknown error', severity: 'error' });
        } else {
            setMessage({ message: errors ? errors[0].message : 'Plan cancelled successfully', severity: 'success' });
            user.activeSubscriptionLevelCode = 'FREE';
        }
        // complete
        setProcessing(false);
        setConfirmOpen(false);
    };
    const onSendEmailChecked = (event) => {
        setSendEmail(!sendEmail);
    };
    const onCancelConfirm = (event) => {
        setConfirmOpen(false);
    };
    if (message) return <Alert severity={message.severity}>{message.message}</Alert>;
    let planDescription;
    let cancelDisabled = false;
    if ((user?.activeSubscriptionLevelCode || 'FREE') === 'FREE' || user?.subscriptionLevelCode === 'FREE') cancelDisabled = true;
    if (user?.paymentMethod === 'IAP') planDescription = <p className="planLabel">IAP: User must cancel</p>;
    if (!user?.paymentMethod) {
        if (!user?.signupCompletedOn) planDescription = <p className="planLabel">User still in signup</p>;
        else planDescription = <p className="planLabel">No Payment method<br />(special user?)</p>;
    }
    return (
        <>
            {!planDescription && <Button onClick={onCancelPlan} disabled={cancelDisabled} color="secondary">Cancel Plan</Button>}
            {!cancelDisabled && planDescription}
            <Dialog open={confirmOpen} onClose={onCancelConfirm}>
                <DialogTitle>
                    Please Confirm
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to cancel the subscription?
                        <FormControlLabel
                            control={
                                <Checkbox checked={sendEmail} onChange={onSendEmailChecked} />
                            }
                            label="Send the email to the user"
                        />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button disabled={processing} onClick={onCancelConfirm}>Cancel</Button>
                    <Button disabled={processing} onClick={onConfirm}>Confirm</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export const ConfirmDialog = (props) => {
    const { title, children, open, setOpen, onConfirm } = props;
    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="confirm-dialog"
        >
            <DialogTitle id="confirm-dialog">{title}</DialogTitle>
            <DialogContent>{children}</DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    onClick={() => setOpen(false)}
                // color="secondary"
                >
                    No
                </Button>
                <Button
                    variant="contained"
                    onClick={() => {
                        setOpen(false);
                        onConfirm();
                    }}
                //  color="default"
                >
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export const deleteUser = ({ authClient, user }) => {
    if (user.subscriptionLevelCode !== 'FREE' && user.signupCompletedOn) {
        return Promise.reject(Error('Please cancel this user\'s paid plan before deleting'));
    }
    return authClient.request(gql`
        mutation ($userId: Int!, $effectiveAsOf: DateTime) {
            deleteUser(userId: $userId, effectiveAsOf: $effectiveAsOf) {
                ok
                errors {
                    path
                    message
                }
            }
        }`,
        {
            userId: user.userId,
            effectiveAsOf: null,
        },
    ).then((result) => {
        const { ok, errors } = result.deleteUser;
        if (!ok) return Promise.reject(Error(errors[0].message));
        return { data: { id: user.userId } };
    });
};

export const createShortenedDateTime = (dateString) => {
    let dateTime = new Date(dateString);
    dateTime = `${dateTime.toLocaleDateString('en-US')} ${dateTime.toLocaleTimeString('en-US')}`;
    return dateTime;
};

export const getAvatarUrl = ({ firstName, lastName, avatarUrl }) => (
    avatarUrl || `https://ui-avatars.com/api/?name=${firstName}+${lastName}`
);

export const formatToUnits = (number, precision) => {
    const abbrev = ['', 'k', 'm', 'b', 't'];
    const unrangifiedOrder = Math.floor(Math.log10(Math.abs(number)) / 3)
    const order = Math.max(0, Math.min(unrangifiedOrder, abbrev.length - 1))
    const suffix = abbrev[order];
    return (number / Math.pow(10, order * 3)).toFixed(precision) + suffix;
};

export const msToHumanReadableTime = (ms) => {
    let seconds = (ms / 1000).toFixed(1);
    let minutes = (ms / (1000 * 60)).toFixed(1);
    let hours = (ms / (1000 * 60 * 60)).toFixed(1);
    let days = (ms / (1000 * 60 * 60 * 24)).toFixed(1);
    if (seconds < 60) return seconds + " Sec";
    else if (minutes < 60) return minutes + " Min";
    else if (hours < 24) return hours + " Hrs";
    else return days + " Days"
};

export const stringToColor = (stringInput) => {
    let stringUniqueHash = [...stringInput].reduce((acc, char) => {
        return char.charCodeAt(0) + ((acc << 5) - acc);
    }, 0);
    return `hsl(${stringUniqueHash % 360}, 95%, 35%)`;
}