import "./widget.scss";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import { useCallback, useContext, useEffect, useState } from "react";
import { getPlanStatisticsSimpleQuery, getWatchLogFSQ } from "../../queries";
import { ApiContext } from "../../context/apiContext";
import { formatToUnits, msToHumanReadableTime } from "../../utils/utils";
import { Alert } from "@mui/material";

const timeWatchedFSQ = {
  "operation": "aggregate",
  "clause": [
    {
      "$match": {
        "updatedAt": {
          // these dates will get replaced when fetched
          "$gt": "2023-01-01T00:00:00z",
          "$lt": "2023-02-01T00:00:00z"
        }
      }
    },
    {
      "$project": {
        "updatedAt": 1,
        "longestVideoTime": 1
      }
    },
    {
      "$group": {
        "_id": {
          "month": {
            "$month": "$updatedAt"
          }
        },
        "totalTime": {
          "$sum": "$longestVideoTime"
        }
      }
    }
  ]
}

const Widget = ({ type }) => {
  let data;
  const [planStats, setPlanStats] = useState({});
  const [timeWatchedStr, setTimeWatchedStr] = useState("--");
  const [isLoading, setIsLoading] = useState(true);
  const { tsdbClient, accountClient } = useContext(ApiContext);
  const [alertMessage, setAlertMessage] = useState({});

  const fetchPlanStats = useCallback(async () => {
    try {
      const result = await accountClient.request(getPlanStatisticsSimpleQuery);
      const { ok, errors, statistics } = result.planStatistics;
      if (!ok) throw Error(errors[0].message);
      const FREE = (statistics.find(stat => stat.subscriptionLevelCode === 'FREE') || {}).count || 0;
      const PREMIUM = (statistics.find(stat => stat.subscriptionLevelCode === 'PREMIUM') || {}).count || 0;
      setPlanStats({ FREE, PREMIUM });
    } catch (error) {
      setAlertMessage({ message: error.message });
    }
  }, [accountClient]);

  const fetchWatchedTime = useCallback(async () => {
    try {
      const today = new Date();
      const thirtyDaysAgo = new Date();
      thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
      const matchIdx = timeWatchedFSQ.clause.findIndex(c => c['$match']);
      timeWatchedFSQ.clause[matchIdx]["$match"].updatedAt = {
        "$gt": thirtyDaysAgo.toISOString(),
        "$lt": today.toISOString()
      };
      const result = await tsdbClient.request(getWatchLogFSQ, timeWatchedFSQ);
      let res;
      try {
        res = JSON.parse(result.getWatchLogFreeStyle);
      } catch (error) { throw Error(`${error.message}: ${result}`); }
      let totalTimeMs = 0;
      res.forEach(item => { totalTimeMs += item.totalTime * 1000; });
      setTimeWatchedStr(msToHumanReadableTime(totalTimeMs));
    } catch (error) {
      setAlertMessage({ message: error.message });
    }
  }, [tsdbClient]);

  useEffect(() => {
    setIsLoading(true);
    if (type !== 'watched') fetchPlanStats().then(() => setIsLoading(false));
    if (type === 'watched') fetchWatchedTime();
  }, [type, fetchPlanStats, fetchWatchedTime]);

  switch (type) {
    case "user":
      data = {
        title: "FREE USERS",
        text: planStats?.FREE ? formatToUnits(planStats.FREE, 1) : "---",
        isMoney: false,
        link: "See free users",
        cornerLabel: "Total",
        icon: (
          <PersonOutlinedIcon
            className="icon"
            style={{
              color: "crimson",
              backgroundColor: "rgba(255, 0, 0, 0.2)",
            }}
          />
        ),
      };
      break;
    case "order":
      data = {
        title: "PREMIUM USERS",
        text: planStats?.PREMIUM ? planStats.PREMIUM.toLocaleString() : "---",
        isMoney: false,
        link: "See premium users",
        cornerLabel: "Total",
        icon: (
          <ShoppingCartOutlinedIcon
            className="icon"
            style={{
              backgroundColor: "rgba(218, 165, 32, 0.2)",
              color: "goldenrod",
            }}
          />
        ),
      };
      break;
    case "earning":
      data = {
        title: "SALES/EST.",
        text: planStats?.PREMIUM ? planStats.PREMIUM * 5 : "---",
        isMoney: true,
        link: "View earnings est.",
        cornerLabel: "Monthly",
        icon: (
          <MonetizationOnOutlinedIcon
            className="icon"
            style={{ backgroundColor: "rgba(0, 128, 0, 0.2)", color: "green" }}
          />
        ),
      };
      break;
    case "watched":
      data = {
        title: "WATCHED",
        text: timeWatchedStr,
        isMoney: false,
        link: "View watch history",
        cornerLabel: "30 days",
        icon: (
          <AccountBalanceWalletOutlinedIcon
            className="icon"
            style={{
              backgroundColor: "rgba(128, 0, 128, 0.2)",
              color: "purple",
            }}
          />
        ),
      };
      break;
    default:
      break;
  }

  return (
    <div className="widget">
      {alertMessage.message && <Alert severity={alertMessage.severity || "error"}>{alertMessage.message}</Alert>}
      <div className="left">
        <span className="title">{data.title}</span>
        <span className="counter">
          {data.isMoney && "$"} {data.text}
        </span>
        <span className="link">{data.link}</span>
      </div>
      <div className="right">
        <div className="percentage positive">
          {data.cornerLabel}
          {/* <KeyboardArrowUpIcon />
          {diff} % */}
        </div>
        {data.icon}
      </div>
    </div>
  );
};

export default Widget;