import "./navbar.scss";

import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import FullscreenExitOutlinedIcon from "@mui/icons-material/FullscreenExitOutlined";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import ListOutlinedIcon from "@mui/icons-material/ListOutlined";
import { DarkModeContext } from "../../context/darkModeContext";
import { AppStateContext } from "../../context/appStateContext";
import { useContext, useRef, } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const Navbar = () => {
    const { dispatch } = useContext(DarkModeContext);
    const { appState } = useContext(AppStateContext);
    const location = useLocation();
    const navigate = useNavigate();
    const searchTimeout = useRef(-1);
    let avatarUrl = '';
    try {
        ({ adminUser: { activeProfile: { avatarUrl } } } = appState);
    } catch (error) {
        console.log(appState);
        console.log('no avatar');
    }

    const handleSearchChanged = (e) => {
        const value = e.target.value;
        if (location.path !== '/users') navigate("/users")
        // console.log(value, location);
        clearTimeout(searchTimeout.current);
        searchTimeout.current = setTimeout(() => {
            document.dispatchEvent(new CustomEvent('emailSearch', { detail: { email: value } }));
        }, 250);
    }

    return (
        <div className="navbar">
            <div className="wrapper">
                <div className="search">
                    <input type="text" placeholder="Email Search..." onChange={handleSearchChanged} />
                    <SearchOutlinedIcon />
                </div>
                <div className="items">
                    <div className="item">
                        <LanguageOutlinedIcon className="icon" />
                        English
                    </div>
                    <div className="item">
                        <DarkModeOutlinedIcon
                            className="icon"
                            onClick={() => dispatch({ type: "TOGGLE" })}
                        />
                    </div>
                    <div className="item">
                        <FullscreenExitOutlinedIcon className="icon" />
                    </div>
                    <div className="item">
                        <NotificationsNoneOutlinedIcon className="icon" />
                        <div className="counter">1</div>
                    </div>
                    <div className="item">
                        <ChatBubbleOutlineOutlinedIcon className="icon" />
                        <div className="counter">2</div>
                    </div>
                    <div className="item">
                        <ListOutlinedIcon className="icon" />
                    </div>
                    <div className="item">
                        <img
                            src={avatarUrl}
                            alt=""
                            className="avatar"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Navbar;