import "./profile.scss";
import Chart from "../../components/chart/Chart";
import { Alert, Box, Tab, Tabs } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import { ApiContext } from "../../context/apiContext";
import { getProfilePreferenceQuery, getProfileQuery } from "../../queries";
import { createShortenedDateTime, getAvatarUrl } from "../../utils/utils";
import WatchLogTable from "../../components/datatables/WatchLogTable";
import Preferences from "../../components/dialogs/Preferences";
import WatchedByProvider from "../../components/chart/WatchedByProvider";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

const Profile = () => {
    const [state, setState] = useState({ profile: {}, isLoading: true });
    const [activeTabIdx, setActiveTabIdx] = useState(0);
    // const [confirmDelete, setConfirmDelete] = useState(false);
    const [preferencesOpen, setPreferencesOpen] = useState(false);
    // const [preferenceData, setPreferenceData] = useState({});
    const [alertMessage, setAlertMessage] = useState({});
    const { accountClient, authClient, tsdbClient } = useContext(ApiContext);
    // const navigate = useNavigate();

    // console.log(userId);
    // const userId = parseInt(useParams().userId);
    const profileId = parseInt(useParams().profileId);

    const { profile } = state;

    useEffect(() => {
        (async () => {
            if (state.profile.profileId === profileId) return;
            state.profile.profileId = profileId; // do this to avoid hitting the server repeatedly on load
            const queue = [];
            queue.push(() => authClient.request(getProfileQuery, {
                id: profileId,
            }).then((result) => ({ data: result.profile })));
            // queue.push(() => accountClient.request(getProviderActivationCodesQuery, { userId }).then((result) => ({
            //     data: result.providerActivationCodes,
            // })));

            const profile = { data: {} };
            await new Promise((resolve) => {
                Promise.all(queue.map(q => q()))
                    .then((results) => {
                        profile.data = results[0].data;
                        // profile.data = { ...profile.data, ...results[1].data };
                        profile.data.avatarUrl = getAvatarUrl({ firstName: profile.data.name, avatarUrl: profile.data.avatarUrl });
                        tsdbClient.request(getProfilePreferenceQuery, { where: { profileId: profile.data.id.toString() } })
                            .then((pref) => {
                                profile.data.preferences = pref.preferences;
                                resolve(profile);
                            });
                    }).catch((error) => {
                        setAlertMessage({ message: error.message });
                    });
            });
            profile.data.profileId = profile.data.id; // so we can be clear about the id we are working with
            setState({ ...state, isLoading: false, profile: profile.data });
            console.log(profile.data);
        })();
    }, [state, accountClient, authClient, tsdbClient, profileId]);

    // const fetchPreferences = () => {
    //     tsdbClient.request(getProfilePreferenceQuery, {
    //         where: {
    //             profileId: profileId.toString(),
    //         },
    //     }).then((result) => {
    //         setPreferenceData(result.preferences);
    //         setPreferencesOpen(true);
    //     }).catch((error) => {
    //         setAlertMessage({ message: error.message });
    //     });
    // };


    const handleTabChange = (event, newValue) => {
        setActiveTabIdx(newValue);
    };

    return (
        <div className="single profile">
            <div className="singleContainer">
                <div className="top">
                    <div className="left">
                        <div className="editButton">Edit</div>
                        <h1 className="title">Profile Information</h1>
                        <div className="item">
                            <div className="details">
                                <h1 className="itemTitle">{profile.name}</h1>
                                <div className="detailItem">
                                    <span className="itemKey">Content PIN For:</span>
                                    <span className="itemValue">{profile.contentPINRestricted}</span>
                                </div>
                                <div className="detailItem">
                                    <span className="itemKey">Filter PIN:</span>
                                    <span className="itemValue">{profile.filterChangesNeedPIN || 'NONE'}</span>
                                </div>
                                <div className="detailItem">
                                    <span className="itemKey">Profile PIN:</span>
                                    <span className="itemValue">{profile.securedByPIN || 'NONE'}</span>
                                </div>
                                <div className="detailItem">
                                    <span className="itemKey">Created:</span>
                                    <span className="itemValue">{createShortenedDateTime(profile.createdAt)}</span>
                                </div>
                                <div className="detailItem">
                                    <span className="itemKey">Updated:</span>
                                    <span className="itemValue">{createShortenedDateTime(profile.updatedAt)}</span>
                                </div>
                            </div>
                            <img
                                src={profile.avatarUrl}
                                alt=""
                                className="itemImg"
                            />
                        </div>
                        <div className="actionButtons">
                            <button className="preferencesButton" onClick={() => setPreferencesOpen(true)}>
                                Default Preferences
                            </button>
                        </div>
                    </div>
                    <div className="right">
                        <WatchedByProvider profiles={[profile]} aspect={3 / 1} title="Watched By Provider" />
                    </div>
                </div>
                {alertMessage.message && <Alert severity={alertMessage.severity || "error"}>{alertMessage.message}</Alert>}
                <div className="bottom">
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={activeTabIdx} onChange={handleTabChange} aria-label="basic tabs example">
                            <Tab label="WatchLog" />
                            <Tab label="WatchList" />
                        </Tabs>
                    </Box>
                    <TabPanel value={activeTabIdx} index={0}>
                        <WatchLogTable profile={profile} />
                    </TabPanel>
                    <TabPanel value={activeTabIdx} index={1}>
                        <h1 className="title">Watch List</h1>
                    </TabPanel>
                </div>
            </div>
            <Preferences preferenceData={profile.preferences} preferencesOpen={preferencesOpen} handlePreferencesClose={() => setPreferencesOpen(false)} />
            <Outlet />
        </div>
    );
};

export default Profile;