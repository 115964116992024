import "./chart.scss";
import {
  XAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
  YAxis,
  Bar,
  BarChart,
} from "recharts";
import { useCallback, useContext, useEffect, useState } from "react";
import { ApiContext } from "../../context/apiContext";
import { getWatchLogFSQ, watchedByProviderFSQ } from "../../queries";
import { Alert } from "@mui/material";
import { stringToColor } from "../../utils/utils";

const WatchedByProvider = ({ aspect, title, profiles }) => {
  const [chartWatchStats, setChartWatchStats] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { tsdbClient } = useContext(ApiContext);
  const [alertMessage, setAlertMessage] = useState({});

  const fetcWatchedByProvider = useCallback(async (startDate, endDate) => {
    if (!profiles || profiles.length === 0 || Object.keys(profiles[0]).length === 0) return;
    try {
      const matchIdx = watchedByProviderFSQ.clause.findIndex(c => c['$match']);
      watchedByProviderFSQ.clause[matchIdx]["$match"].updatedAt = {
        "$gt": startDate.toISOString(),
        "$lt": endDate.toISOString()
      };
      watchedByProviderFSQ.clause[matchIdx]["$match"].profileId = { "$in": profiles.map(p => p.id.toString()) }
      const result = await tsdbClient.request(getWatchLogFSQ, watchedByProviderFSQ);
      let res;
      const data = [];
      try {
        res = JSON.parse(result.getWatchLogFreeStyle);
        res.forEach((r) => {
          const { siteName, profileId } = r._id;
          const idx = data.findIndex(d => d.siteName === siteName);
          if (idx > -1) {
            data[idx][`count-${profileId}`] = r.count;
          } else {
            data.push({ siteName, [`count-${profileId}`]: r.count });
          }
        });
      } catch (error) { throw Error(`${error.message}: ${result}`); }
      console.log(data);
      setChartWatchStats(data);
      return res;
    } catch (error) {
      setAlertMessage({ message: error.message });
    }
  }, [tsdbClient, profiles]);

  useEffect(() => {
    setIsLoading(true);
    setChartWatchStats([]);
    const today = new Date();
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30 * 6);
    fetcWatchedByProvider(thirtyDaysAgo, today).then(() => {
      setIsLoading(false);
      // console.log('done loading plan stats', data);
    });
    // fetchPlanStats("2021-12-31 03:44:20", "2022-01-31 03:44:20");
  }, [fetcWatchedByProvider]);

  const color = ["#8884d8", "#82ca9d", "orange", "pink"]

  const generateStackedBars = () => {
    return (profiles || []).map((p, idx) => (
      <Bar
        key={`_${idx}`}
        dataKey={`count-${p.id}`}
        name={p.name}
        stackId="a"
        fill={color[idx >= color.length - 1 ? stringToColor(p.name) : idx]}
        stroke="#000000"
        strokeWidth={0.2}
      />
    ));
  };

  return (
    <div className="chart">
      <div className="title">{title} (Last 6 Months)</div>
      {alertMessage.message && <Alert severity={alertMessage.severity || "error"}>{alertMessage.message}</Alert>}
      <ResponsiveContainer width="100%" aspect={aspect} maxHeight={335}>
        <BarChart
          width={500}
          height={300}
          data={chartWatchStats}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="siteName" />
          <YAxis />
          <Tooltip />
          <Legend />
          {generateStackedBars()}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default WatchedByProvider;